import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import userContext from "../context/UserContext";

export default function AssignTasks() {
  const [taskData, setTaskData] = useState([]);
  const [taskStandard, setTaskStandard] = useState([]);
  const [createdAtOptions, setCreatedAtOptions] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState('');
  const [selectedCreatedAt, setSelectedCreatedAt] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [error, setError] = useState(null);
  const { token } = useContext(userContext);

  useEffect(() => {
    const fetchTaskStandard = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/assignments/standards`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaskStandard(response.data.data || []);
        const createdAtValues = [...new Set(response.data.data.map(task => task.created_at))];
        setCreatedAtOptions(createdAtValues);
      } catch (error) {
        console.error("Error fetching task standards:", error.response ? error.response.data : error.message, error);
        setError('Error fetching task standards');
      }
    };
    fetchTaskStandard();
  }, [token]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/assignments`, {
          params: {
            standard: selectedStandard,
            createdAt: selectedCreatedAt,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaskData(response.data.data || []);
      } catch (error) {
        console.error("Error fetching tasks:", error.response ? error.response.data : error.message);
        setError('Error fetching tasks');
      }
    };
    if (selectedStandard || selectedCreatedAt) {
      fetchTasks();
    }
  }, [selectedStandard, selectedCreatedAt, token]);

  useEffect(() => {
    const filtered = taskData.filter(task => {
      return (
        (selectedStandard ? task.task_standard === selectedStandard : true) &&
        (selectedCreatedAt ? task.created_at === selectedCreatedAt : true)
      );
    });
    setFilteredTasks(filtered);
  }, [taskData, selectedStandard, selectedCreatedAt]);

  const handleStandardChange = (e) => {
    setSelectedStandard(e.target.value);
  };

  const handleCreatedAtChange = (e) => {
    setSelectedCreatedAt(e.target.value);
  };

  return (
    <div className="container">
      {error && <div className="alert alert-danger">{error}</div>}
      <h1>Assign Tasks</h1>
      <div className="row mb-3">
        <div className="col-md-6">
          <select value={selectedStandard} onChange={handleStandardChange} className="form-control">
            <option value="">Select Task Standard</option>
            {taskStandard.map((standard) => (
              <option key={standard.syllabusid} value={standard.task_standard}>
                {standard.task_standard}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <select value={selectedCreatedAt} onChange={handleCreatedAtChange} className="form-control">
            <option value="">Select Created At</option>
            {createdAtOptions.map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Created At</th>
            <th>Task Standard</th>
            <th>Subject</th>
            <th>Syllabus ID</th>
            <th>Task</th>
          </tr>
        </thead>
        <tbody>
          {filteredTasks.length === 0 ? (
            <tr>
              <td colSpan="5">No tasks found</td>
            </tr>
          ) : (
            filteredTasks.map((task) => (
              <tr key={task.syllabusid}>
                <td>{task.created_at}</td>
                <td>{task.task_standard}</td>
                <td>{task.subject}</td>
                <td>{task.syllabus_id}</td>
                <td>{task.task}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}